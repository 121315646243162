import Navbar from "./Navbar";
import Sidebar from "./Sidebar";
import { ReactNode } from "react";

interface LayoutProps {
   pageTitle: string;
   children: ReactNode;
 }

const Layout = ({ children }: LayoutProps) => {
    return (
        <div className="h-screen relative">
            <div className="w-full lg:w-[calc(100%)] md:w-[calc(100%-256px)] fixed right-0 z-20 bg-white-100 pb-3 md:pb-0 h-[70px]  border-b-4 border-green-100 ">
                <Navbar />
            </div>
            <div className="relative md:pl-64 h-full">
                <div className="hidden md:block fixed bg-black-100 left-0 w-[280px] mt-[70px] h-[100%] overflow-y-auto">
                    <Sidebar/>
                </div>
                <main
                    className={`w-full h-full overflow-y-auto py-5 lg:px-2 `}
                >
                    {children}
                </main>
            </div>
        </div>
    )
}

export default Layout;