import { Table } from "@mantine/core";
import Layout from "../../Components/Layout";
import { BsThreeDots } from "react-icons/bs";

const Users = () => {
    const tableHead = [
        "",
        "first name",
        "last name",
        "email address",
        "username",
        "status",
        "",
    ]

    const elements = [{
        user: {
            firstName: "John",
            lastName: "Doe",
            email: "ychag@example.com",
            username: "johndoe",
            status: "active",
        }
    },
    {
        user: {
            firstName: "John",
            lastName: "Doe",
            email: "ychag@example.com",
            username: "johndoe",
            status: "active",
        }
    },
]

    const rows = elements.map((item, index) => (
        <tr key={index}>
            <td>{index + 1}</td>
            <td>{item.user.firstName}</td>
            <td>{item.user.lastName}</td>
            <td>{item.user.email}</td>
            <td>{item.user.username}</td>
            <td>{item.user.status}</td>
            <td
                className="cursor-pointer"
                data-testid="view_application"
                // onClick={
                //     () => {
                //         setActiveId(item._id)
                //         setPhase(2)
                //     }
                //     // navigate(`/applications/${item._id}`)
                // }
            >
                <BsThreeDots size={30} style={{ color: "#889088" }} />
            </td>
        </tr>
    ))

    return (
        <Layout pageTitle="Users">
            <div className="pt-20 pl-16 pr-4">
                <h1 className="text-2xl font-bold pt-2">ALL USERS</h1>
                <div className="hidden lg:block mt-8 border border-green-30 rounded-lg border-2">
                    <Table verticalSpacing="md">
                        <thead>
                            <tr>
                                {tableHead.map((item, index) => (
                                    <>
                                        <th
                                            key={index}
                                            style={{
                                                // color: "rgba(15, 13, 0, 0.3)",
                                                fontSize: "13px",
                                                borderBottom: "none",
                                            }}
                                            className="text-black-100 font-bold"
                                        >
                                            {item.toUpperCase()}
                                        </th>
                                    </>
                                ))}
                            </tr>
                        </thead>
                        <tbody>{rows}</tbody>
                    </Table>
                </div>
            </div>
        </Layout>
    )
}

export default Users;