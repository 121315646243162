import { Dashboard } from "./Assets/Dashboard";
import { Listings } from "./Assets/listings";
import { Postings } from "./Assets/postings";
import { Withdrawal } from "./Assets/Withdrawal";
import { Users } from "./Assets/users";

export interface SidebarRoutes {
    icon: React.FunctionComponent
    title: string
    route: string
}

export const adminRoute: SidebarRoutes[] = [
    { icon: Dashboard, title: "Dashboard", route: "/admin/dashboard" },
    { icon: Users, title: "Users", route: "/admin/users" },
    { icon: Listings, title: "Listings", route: "/admin/listings" },
    { icon: Postings, title: "Deposits", route: "/admin/deposits" },
    { icon: Withdrawal, title: "Withdrawal", route: "/admin/withdrawal" },
    { icon: Dashboard, title: "Ticket", route: "/admin/ticket" },
    { icon: Postings, title: "Rooms", route: "/admin/rooms" },
    // { icon: Dashboard, title: "All users", route: "/all-users" },
    // { icon: Dashboard, title: "Roles and Permission", route: "/roles-permission" },
]