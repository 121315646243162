export const Dashboard = () => {
    return (
        <svg
            width="25" 
            height="25" 
            viewBox="0 0 25 25" 
            fill="none" 
            xmlns="http://www.w3.org/2000/svg"
        >
            <path d="M8.33333 25H2.77778C1.25 25 0 23.75 0 22.2222V2.77778C0 1.25 1.25 0 2.77778 0H8.33333C9.86111 0 11.1111 1.25 11.1111 2.77778V22.2222C11.1111 23.75 9.86111 25 8.33333 25ZM16.6667 25H22.2222C23.75 25 25 23.75 25 22.2222V15.2778C25 13.75 23.75 12.5 22.2222 12.5H16.6667C15.1389 12.5 13.8889 13.75 13.8889 15.2778V22.2222C13.8889 23.75 15.1389 25 16.6667 25ZM25 6.94444V2.77778C25 1.25 23.75 0 22.2222 0H16.6667C15.1389 0 13.8889 1.25 13.8889 2.77778V6.94444C13.8889 8.47222 15.1389 9.72222 16.6667 9.72222H22.2222C23.75 9.72222 25 8.47222 25 6.94444Z" fill="#1E6262"/>
        </svg>
    )
}