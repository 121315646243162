import Krew54Logo from "../../Assets/krew54-logo.svg";
import AdminLogo from "../../Assets/admin.svg";


const Navbar = () => {
    return (
        <nav className={`w-full`}>
            <div className=" flex items-center gap-6 mr-4 lg:mr-12 ">
                {/* <div className=" md:hidden cursor-pointer mr-auto ml-6">
                    <HiMenuAlt2 size={28} onClick={() => setOpenSideBar(true)} />
                </div> */}
                <div className='flex ml-16 mt-2'>
                    <img src={Krew54Logo} alt="krew54 logo"/>
                    <img src={AdminLogo} alt="admin logo" className='self-start'/>
                </div>
                {/* <Indicator
                    label={unreadNotification?.length}
                    size={16}
                    color="#E94444"
                >
                    <IoIosNotifications
                        className="cursor-pointer"
                        data-testid="notification"
                        onClick={() => setOpened((state) => !state)}
                        size={22}
                    />
                </Indicator>
                <img
                    src={SettingsCog}
                    alt="SettingsCog icon"
                    className="cursor-pointer"
                    onClick={() => navigate("/settings")}
                />
                <img
                    src={User}
                    alt="User icon"
                    className="cursor-pointer"
                />
                <img
                    src={Logout}
                    alt="Logout icon"
                    className="cursor-pointer"
                    onClick={() =>
                        handleLogOut(
                            state.jwt?.token,
                            showNotification,
                            dispatch,
                            navigate
                        )
                    }
                /> */}
            </div>
        </nav>
    )
}

export default Navbar;