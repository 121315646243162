import React from 'react'
import Footer from './Footer'
import Navbar from './Navbar'

const TermsAndCondition = () => {
    React.useEffect(() => {
        window.scrollTo(0, 0)
    }, [])
    return (
        <div>
            <Navbar />
            <div className="pt-6 px-6 pb-[244px] md:pt-[167px] md:px-[155px] md:pb-[500px] text-justify">
                <h3 className="text-black text-[27px] font-semibold pb-5 md:pb-11">
                    Terms &#38; Conditions
                </h3>
                <h5 className="text-lg font-bold pb-4">Introduction</h5>
                <p className="pb-4">
                    We are a social network and online platform for
                    professionals. People use our Services to find and be found
                    for business opportunities, to connect with others and find
                    information. Our Privacy Policy applies to any Member or
                    Visitor to our Services.{' '}
                </p>{' '}
                <span>
                    Our registered users (“Members”) share their professional
                    identities, engage with their network, exchange knowledge
                    and professional insights, post and view relevant content,
                    learn and develop skills, and find business and career
                    opportunities. Content and data on some of our Services is
                    viewable to non-members (“Visitors”).
                </span>
                <p className="pt-4">
                    We use the term “Designated Countries” to refer to countries
                    in the European Union (EU), European Economic Area (EEA),
                    and Switzerland.
                </p>
                {'Services'}
                <h5 className="text-lg font-bold pt-5 pb-4">
                    This Privacy Policy, including our Cookie Policy applies to
                    your use of our Services.{' '}
                </h5>
                <p>
                    This Privacy Policy applies to LinkedIn.com,
                    LinkedIn-branded apps, LinkedIn Learning and other
                    LinkedIn-related sites, apps, communications and services
                    (“Services”), including off-site Services, such as our ad
                    services and the “Apply with LinkedIn” and “Share with
                    LinkedIn” plugins, but excluding services that state that
                    they are offered under a different privacy policy. For
                    California residents, additional disclosures required by
                    California law may be found in our California Privacy
                    Disclosure. Data Controllers and Contracting Parties
                </p>
                <p className="pt-4">
                    If you are in the “Designated Countries”, LinkedIn Ireland
                    Unlimited Company (“LinkedIn Ireland”) will be the
                    controller of your personal data provided to, or collected
                    by or for, or processed in connection with our Services.
                </p>{' '}
                <p className="pt-4">
                    If you are outside of the Designated Countries, LinkedIn
                    Corporation will be the controller of your personal data
                    provided to, or collected by or for, or processed in
                    connection with, our Services.
                </p>
                <p className="pt-4">
                    As a Visitor or Member of our Services, the collection, use
                    and sharing of your personal data is subject to this Privacy
                    Policy and other documents referenced in this Privacy
                    Policy, as well as updates.
                </p>
            </div>

            <Footer />
        </div>
    )
}

export default TermsAndCondition
