
export const Postings = () => {
    return (
        <svg    
            width="25" 
            height="25" 
            viewBox="0 0 38 30" 
            fill="none" 
            xmlns="http://www.w3.org/2000/svg"
        >
            <path d="M24.0001 0C24.4421 0 24.866 0.175595 25.1786 0.488155C25.4911 0.800716 25.6667 1.22464 25.6667 1.66667V5H32.3334C32.7754 5 33.1994 5.17559 33.5119 5.48816C33.8245 5.80072 34.0001 6.22464 34.0001 6.66667V26.6667H37.3334V30H0.666748V26.6667H4.00008V6.66667C4.00008 6.22464 4.17568 5.80072 4.48824 5.48816C4.8008 5.17559 5.22472 5 5.66675 5H12.3334V1.66667C12.3334 1.22464 12.509 0.800716 12.8216 0.488155C13.1341 0.175595 13.5581 0 14.0001 0H24.0001ZM15.6667 8.33333H12.3334V26.6667H15.6667V8.33333ZM25.6667 8.33333H22.3334V26.6667H25.6667V8.33333ZM22.3334 3.33333H15.6667V5H22.3334V3.33333Z" fill="#1E6262"/>
        </svg>
    )
}