import React, { MutableRefObject } from 'react';
import Krew54Logo from "../../Assets/krew54-logo.svg";
import AdminLogo from "../../Assets/admin.svg";
import { NavLink, useNavigate, useLocation } from "react-router-dom";
import { TextInput, PasswordInput, Alert, Button } from "@mantine/core";
import { useForm } from "@mantine/form";

const Login = () => {
    const [error, showError] = React.useState(false);
    const [isSubmitting, setIsSubmitting] = React.useState(false);
    const [errorMsg, setErrorMsg] = React.useState("")
    const userRef = React.useRef<HTMLInputElement>(
        null
    ) as MutableRefObject<HTMLInputElement>
    const loginForm = useForm({
        initialValues: {
            username: "",
            password: "",
        },

        // validate: {
        //     email: (value) =>
        //         /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(value)
        //             ? null
        //             : "Invalid email",
        // },
    })

    React.useEffect(() => {
        userRef.current.focus()
        setErrorMsg("")
    }, [])

    const navigate = useNavigate();
    const location = useLocation();
    const from = location.state?.from?.pathname || "/admin/dashboard";

    const handleLogin = ({username, password}:{username: string, password: string}) => {
        setIsSubmitting(true);
        navigate(from)
    }

    return (
        <div>
            <div className='flex ml-36 mt-5'>
                <img src={Krew54Logo} alt="krew54 logo"/>
                <img src={AdminLogo} alt="admin logo" className='self-start'/>
            </div>
            <div className='flex flex-col items-center mt-12'>
                <h3 className='text-2xl py-2'>KREW54 Admin</h3>
                <span>Complete the form below to sign in</span>
                <div className='bg-green-10 py-16 px-12 lg:w-[500px] mt-4'>
                    <form onSubmit={loginForm.onSubmit((values) =>
                        handleLogin(values)
                    )}>
                        <div onFocusCapture={() => showError(false)}>
                            <TextInput
                                label="Username"
                                name="email"
                                placeholder="Username"
                                ref={userRef}
                                withAsterisk
                                type="text"
                                // error={loginForm.errors.email}
                                // helperText={loginForm.errors.email}
                                {...loginForm.getInputProps("username")}
                                styles={() => ({
                                    input: {
                                        height: '54px'
                                    }
                                })}
                            />
                        </div>
                        
                        <PasswordInput
                            placeholder="password"
                            id="password"
                            label="Password"
                            withAsterisk
                            required
                            {...loginForm.getInputProps("password")}
                            onFocusCapture={() => showError(false)}
                            styles={() => ({
                                input: {
                                    height: '54px',
                                },
                                label: {
                                    paddingTop: '20px'
                                },
                                innerInput:{
                                    paddingTop: '15px'
                                }
                            })}
                        />
                        <div className='grid'>
                            <NavLink to='/admin/forgot-password' className='place-self-end'>
                                <span className=' text-sm text-green-20'>Forgot Password?</span>
                            </NavLink>
                        </div>
                    
                        <Button
                            type="submit"
                            disabled={isSubmitting}
                            style={
                                !isSubmitting
                                    ? {
                                        backgroundColor: "rgba(30, 98, 98, 1)",
                                        height: '54px',
                                    }
                                    : {
                                        backgroundColor: "rgba(30, 98, 98, 1)",
                                        opacity: "0.7",
                                        height: '54px',
                                    }
                            }
                            className="mt-6 font-bold text-base w-full text-center rounded-md"
                        >
                            {!isSubmitting ? "Sign in" : "Loading..."}
                        </Button>
                    </form>
                    {error && (
                        <Alert
                            id="alert"
                            title="Error!"
                            color="red"
                            styles={() => ({ root: { marginTop: "20px" } })}
                        >
                            {errorMsg}
                        </Alert>
                    )}
                </div>
            </div>    
        </div>
    )
}

export default Login;