import { adminRoute } from "./Routes";
import { NavLink } from "react-router-dom";
import ProfileImage from '../../Assets/profileImage.svg';
import Logout from '../../Assets/logout.svg';

const Sidebar = () => {
    return (
        <aside className="w-full text-base pb-8 pt-4">
            <section className="flex pl-24 p-3 rounded-lg ml-1 mb-4 mr-1 bg-ash-10 pl-8">
                {/* {data?.profileImageUrl === null ? ( */}
                    <img
                        className="inline rounded-full px-3 border mr-1"
                        src={ProfileImage}
                        alt="profileImage"
                    />
                {/* // ) : (
                //     <img
                //         className="inline rounded-full p-2"
                //         src={data?.profileImageUrl}
                //         alt="profileImage"
                //     />
                // )} */}
                <div className="p-1">
                    <p className="text-base font-medium">{"Alex Garcia"}</p>
                    <p className="text-sm font-light">{"help@email.com"}</p>
                </div>
            </section>
        <ul className="pb-4">
            {adminRoute.map((item, index) => {
                return (
                    <NavLink
                        to={item.route}
                        key={index}
                        className={({ isActive }) =>
                            isActive
                                ? "flex p-4 bg-green-30 pl-20 font-medium text-green-100"
                                : "flex p-4 text-green-100 pl-20 font-medium"
                        }
                    >
                        <item.icon />
                        <span className="pl-4 cursor-pointer">
                            {item.title}
                        </span>
                    </NavLink>
                )
            })}
        </ul>
        {/* <hr className="bg-white-10 border-2 w-10/12 m-auto" /> */}
        <section className="flex items-center pl-16 cursor-pointer">
            {/* <img
                src={Setting}
                alt=""
                className="cursor-pointer"
                onClick={() => navigate("/settings")}
            /> */}
            <img
                src={Logout}
                alt="log out icon"
                className="cursor-pointer pl-2"
                // onClick={() =>
                //     handleLogOut(
                //         state.jwt?.token,
                //         showNotification,
                //         dispatch,
                //         navigate
                //     )
                // }
            />
            <span className="font-medium pl-2 text-green-100">Log Out</span>
        </section>
        </aside>
    )
}

export default Sidebar;