import playStore from '../Assets/Images/playstore.png'
import './app-module.css'

const PlayStore = () => {
    return (
        <a
            href="https://play.google.com/store/apps/details?id=com.krew54.krew54"
            className="flex items-center p-1 px-3 rounded-md bg-black text-white-100 hover:shadow-2xl transition duration-500"
        >
            <img
                src={playStore}
                width={30}
                height={30}
                alt="play store"
                className="playstore-icon mr-2"
            />
            <div>
                <p className="text-xs font-light uppercase">get it on</p>
                <span className="text-sm font-medium">Google Play</span>
            </div>
        </a>
    )
}

export default PlayStore
