
export const Users = () => {
    return (
        <svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clip-path="url(#clip0_1664_4473)">
                <path d="M19.9998 22.5769V21.03C21.8365 19.8656 23.3332 16.965 23.3332 14.0625C23.3332 9.40313 23.3332 5.625 18.3332 5.625C13.3332 5.625 13.3332 9.40313 13.3332 14.0625C13.3332 16.965 14.8298 19.8656 16.6665 21.03V22.5769C11.0132 23.0963 6.6665 26.2219 6.6665 30H29.9998C29.9998 26.2219 25.6532 23.0963 19.9998 22.5769Z" fill="#1E6262"/>
                <path d="M8.52 23.2997C9.96 22.2404 11.7517 21.436 13.7233 20.9447C13.3219 20.409 12.9685 19.8299 12.6683 19.216C11.8865 17.6402 11.4703 15.8681 11.4583 14.0635C11.4583 11.5435 11.4583 9.16223 12.255 7.2141C13.0283 5.3241 14.42 4.15223 16.4017 3.71348C15.9617 1.47285 14.7883 0.000976562 11.6667 0.000976562C6.66667 0.000976562 6.66667 3.7791 6.66667 8.43848C6.66667 11.341 8.16333 14.2416 10 15.406V16.9529C4.34667 17.4722 0 20.5979 0 24.376H7.265C7.64333 23.9972 8.06167 23.6391 8.52 23.3016V23.2997Z" fill="#1E6262"/>
            </g>
            <defs>
                <clipPath id="clip0_1664_4473">
                    <rect width="30" height="30" fill="white"/>
                </clipPath>
            </defs>
        </svg>
    )
}