import React from 'react';
import Krew54Logo from "../../Assets/krew54-logo.svg";
import AdminLogo from "../../Assets/admin.svg";
import { NavLink, useNavigate } from "react-router-dom";
import { PasswordInput, Alert, Button } from "@mantine/core";
import { useForm } from "@mantine/form";

const ResetPassword = () => {
    const [error, showError] = React.useState(false);
    const [isSubmitting, setIsSubmitting] = React.useState(false);
    const [errorMsg, setErrorMsg] = React.useState("")
    
    const resetPasswordForm = useForm({
        initialValues: {
            newPassword: "",
            confirmPassword: "",
        },

        validate: {
            confirmPassword: (value, values) =>
                value !== values.newPassword ? (
                    <span className="text-[14px]">Passwords did not match</span>
                ) : null,
        },
    })

    const navigate = useNavigate();
    showError(false)
    setErrorMsg("")

    const handleResetPassword = ({newPassword, confirmPassword}:{newPassword: string, confirmPassword: string}) => {
        setIsSubmitting(true);
        navigate("/admin/reset-password")
    }
    return (
        <div>
            <div className='flex ml-36 mt-5'>
                <img src={Krew54Logo} alt="krew54 logo"/>
                <img src={AdminLogo} alt="admin logo" className='self-start'/>
            </div>
            <div className='flex flex-col items-center mt-12'>
                <h3 className='text-2xl py-2'>Set Password</h3>
                <span>Set your new Password</span>
                <div className='bg-green-10 py-16 px-12 lg:w-[500px] mt-4'>
                    <form onSubmit={resetPasswordForm.onSubmit((values) =>
                        handleResetPassword(values)
                    )}>
                        <PasswordInput
                            placeholder="new password"
                            label="Enter new password"
                            withAsterisk
                            required
                            {...resetPasswordForm.getInputProps(
                                "newPassword"
                            )}
                            styles={() => ({
                                innerInput: {
                                    paddingTop: '15px',
                                },
                                input: {
                                    height: '54px'
                                }
                            })}
                        />

                        <PasswordInput
                            placeholder="new password"
                            label="Confirm password"
                            withAsterisk
                            required
                            {...resetPasswordForm.getInputProps(
                                "confirmPassword"
                            )}
                            styles={() => ({
                                input: {
                                    height: '54px',
                                },
                                label: {
                                    paddingTop: '20px'
                                },
                                innerInput: {
                                    paddingTop: '15px'
                                }
                            })}
                        />
                        
                        <Button
                            type="submit"
                            disabled={isSubmitting}
                            style={
                                !isSubmitting
                                    ? {
                                        backgroundColor: "rgba(30, 98, 98, 1)",
                                        height: '54px',
                                    }
                                    : {
                                        backgroundColor: "rgba(30, 98, 98, 1)",
                                        opacity: "0.7",
                                        height: '54px',
                                    }
                            }
                            className="mt-6 font-bold text-base w-full text-center rounded-md"
                        >
                            {!isSubmitting ? "Send Reset Link" : "Loading..."}
                        </Button>
                    </form>
                    <div className='text-center text-green-20 text-sm mt-6'>
                        <span>Remember now?</span>
                        <span className='font-medium pl-2'>
                            <NavLink to="/admin/login">Sign in</NavLink>
                        </span>
                    </div>

                    {error && (
                        <Alert
                            id="alert"
                            title="Error!"
                            color="red"
                            styles={() => ({ root: { marginTop: "20px" } })}
                        >
                            {errorMsg}
                        </Alert>
                    )}
                </div>
            </div>    
        </div>
    )
}

export default ResetPassword;