import apple from '../Assets/Images/apple-logo.png'

const AppStore = () => {
    return (
        <a
            href="https://apps.apple.com/us/app/krew54/id6503250396"
            className="flex items-center p-1 px-3 rounded-md bg-black text-white-100 hover:shadow-2xl transition duration-500"
        >
            <img
                src={apple}
                width={30}
                height={30}
                alt="app store"
                className="appstore-icon mr-2"
            />
            <div>
                <p className="text-xs font-light">Download on the</p>
                <span className="text-md font-medium">App Store</span>
            </div>
        </a>
    )
}

export default AppStore
