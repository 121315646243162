import {Routes, Route, Navigate} from 'react-router-dom';
import App from './App';
import TermsAndCondition from './Components/TermsAndCondition';
import Privacy from './Components/Privacy';
import Login from './Admin/Pages/Auth/Login';
import ResetPassword from './Admin/Pages/Auth/ResetPassword';
import ForgotPassword from './Admin/Pages/Auth/ForgotPassword';
import Dashboard from './Admin/Pages/Dashboard';
import Users from './Admin/Pages/Users';


const Main = () => {
    return (
        <Routes>
            {/* Public Route */}
            <Route path='/' element={<App />} />
            <Route path='/legal/terms-conditions' element={<TermsAndCondition />} />
            <Route path='/legal/privacy-policy' element={<Privacy />} />
            <Route path='/admin/login' element={<Login />} />
            <Route path='/admin/forgot-password' element={<ForgotPassword />} />
            <Route path='/admin/reset-password' element={<ResetPassword />} />
            <Route path='/admin' element={<Navigate replace to={'/admin/login'} />} />
            {/* Protected Route */}
            <Route path='/admin/dashboard' element={<Dashboard />} />
            <Route path='/admin/users' element={<Users />} />
            <Route path='/admin/deposits' element={<Dashboard />} />
            <Route path='/admin/listings' element={<Dashboard />} />
            <Route path='/admin/rooms' element={<Dashboard />} />
            <Route path='/admin/withdrawal' element={<Dashboard />} />
            <Route path='/admin/ticket' element={<Dashboard />} />
        </Routes>
    )
}

export default Main