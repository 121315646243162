import Layout from "../../Components/Layout"

const Dashboard = () => {
    return (
        <Layout pageTitle="Dashboard">
            <div className="pt-20 pl-16 pr-4">
                <h1 className="text-2xl font-bold pt-2">DASHBOARD</h1>
                <div className="flex gap-[10px] pt-[33px] flex-wrap">
                    <div className="rounded-lg bg-[#32A0A0] w-[230px] h-[80px]">
                        <p className="p-2 text-white-100">Users</p>
                    </div>
                    <div className="rounded-lg bg-[#AB43C5] w-[230px] h-[80px]">
                        <p className="p-2 text-white-100">Postings</p>
                    </div>
                    <div className="rounded-lg bg-[#E4BA87] w-[230px] h-[80px]">
                        <p className="p-2 text-white-100">Rooms</p>
                    </div>
                    <div className="rounded-lg bg-[#C32467] w-[230px] h-[80px]">
                        <p className="p-2 text-white-100">Volume</p>
                    </div>
                </div>
                <div className="flex gap-[10px] flex-wrap pt-4">
                    <div className="rounded-lg bg-[#32A0A01A] w-[230px] h-[230px] flex flex-col justify-between p-2">
                        <p className="text-green-100">Users</p>
                        <div className="text-green-100 pb-4">
                            <p className="font-bold text-xl">278,923</p>
                            <span>Total</span>
                        </div>
                    </div>
                    <div className="rounded-lg bg-[#AB43C51A] w-[230px] h-[230px] flex flex-col justify-between p-2">
                        <p className="text-green-100">Listings</p>
                        <div className="text-green-100 pb-4">
                            <p className="font-bold text-xl">128</p>
                            <span>Total</span>
                        </div>
                    </div>
                    <div className="rounded-lg bg-[#E4BA871A] w-[230px] h-[230px] flex flex-col justify-between p-2">
                        <p className="text-green-100">Deposits</p>
                        <div className="text-green-100 pb-4">
                            <p className="font-bold text-xl">EUR 254,000</p>
                            <span>Total</span>
                        </div>
                    </div>
                    <div className="rounded-lg bg-[#C324671A] w-[230px] h-[230px] flex flex-col justify-between p-2">
                        <p className="text-green-100">Volume</p>
                        <div className="text-green-100 pb-4">
                            <p className="font-bold text-xl">EUR 354,000</p>
                            <span>Total</span>
                        </div>
                    </div>
                </div>
            </div>
        </Layout>
    )
}

export default Dashboard;